import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import linkedin from '../assets/images/linkedinb.png';
import instagram from '../assets/images/instab.png';
import github from "../assets/images/githubb.png";
import my_logo from "../assets/images/logov2.svg";


export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
      <section className="navi">
      <Navbar sticky="top" expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            {<img className="logo-image" src={my_logo} alt="Logo" width="50"/>}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>home</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>projects</Nav.Link>
              {/* <Nav.Link href="http://nibbleaday.com">blog</Nav.Link> */}

            </Nav>
            <span className="navbar-text">
              <div className="link-icons">
              <a href="http://linkedin.com/in/alexis-huang-b6991b1a6/"><img src={linkedin} alt="linkedin" width="20" height="20"/></a>
              </div>
              <div className="link-icons">
              <a href="http://github.com/alexisyhuang/"><img src={github} alt="github" width="20" height="20"/></a>
              </div>
              <div className="link-icons">
              <a href="http://instagram.com/alexishuang/"><img src={instagram} alt="instagram" width="20" height="20"/></a>


              </div>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </section>
  )
}