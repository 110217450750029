import {Button, Container, Row, Col} from "react-bootstrap";
import companies from "../assets/images/companies_logos.png";
import languages from "../assets/images/languagesv2.svg";
import blog from "../assets/images/blogiconv2.svg";
import ScrollAnimation from 'react-animate-on-scroll';

export const Content = () => {
    return(
    <section>
        
        <Container>
            <Row className="align-items-center bubbles">
                <Col xs={12} sm = {12} md={4} xl={4}>
                    <img src={companies} height="400" alt="company logos"/>
                    <h3>industry experience</h3>
                    <p>I have previously had roles in software engineering, project management, machine learning, cybersecurity, database management, UI/UX design, soft robotics, 3D printing, wearable technologies and more at companies of varying sizes, from those with hundreds and thousands of employees to early seed stage startups.</p>

                </Col>
                <Col xs={12} sm = {12} md={4} xl={4}>
                    <img src={languages} height="400" alt="languages"/>
                    <h3>languages</h3>
                    <p>💻 I have experience programming in Python, Golang, C++, C, HTML and CSS, Javascript, Java, and React.</p>
                    <p>🎨 I have design experience using Adobe Illustrator, Photoshop, Indesign, and Procreate.</p>
                    <p>I'm also fluent in Mandarin and Korean!</p>
                </Col>
                
                <Col xs={12} sm = {12} md={4} xl={4}>
                    <img src={blog} height="400" alt="company logos"/>
                    <h3>study blog</h3>
                    <p>est 2018, posting articles giving academic advice and detailing my school life is an outlet for me to share my creativity with the world.</p>
                    <a href="http://nibbleaday.com" target="_blank">
                        <Button className="resume-button">read my latest blog post!</Button>
                    </a>
                    
                </Col>
            </Row>
        </Container>
    </section>
    )
}