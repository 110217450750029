import {Button, Card} from "react-bootstrap";
import {Container, Row, Col} from "react-bootstrap";
import blog_pic from "../assets/images/blog_pic.png";
import contact_pic from "../assets/images/contact_me.svg"
import ScrollAnimation from 'react-animate-on-scroll';

export const Contact = () =>  {
  return (
    <section className="contact">
    <ScrollAnimation animateIn="fadeInUp">
    <Container>

        <Row className="align-items-center">


            <Col xs={12} sm = {12} md={7} xl={7}>
                
                <img src={contact_pic} width="600" alt="Contact me"/>
                
            </Col>
            <Col xs={12} sm = {12} md={5} xl={5}>
                <h5 className="sendemail">send me an email at alexishu@mit.edu</h5>
                <p className="sendemail">I'm looking for book recommendations :)</p>
                <Button className="contact-button left-justify" onClick={() => window.location = 'mailto:alexishu@mit.com'}><h3>say hello!</h3></Button>
                {/*
                <Card border = "light" style={{ width: '30rem' }}>
                <Card.Img variant="top" width="5" src={blog_pic} alt="blog pic"/>
                <Card.Body>
                    <Card.Title> study blog </Card.Title>
                    <Card.Text>
                    Writing has been one of my passions since I first fell in love with books in elementary school reading Beverly Cleary's books and all about warrior cats. Since 2018, I've been running a study blog called nibble a day documenting my school life both inside and outside of the classroom, hoping to offer helpful advice somewhere along the way.
                    </Card.Text>
                    
                        <a href="http://nibbleaday.com">
                            <Button className="resume-button">
                            read my latest post!
                            </Button>
                        </a>
                </Card.Body>
                </Card>
                */}
            </Col>

        </Row>
        
    </Container>
    </ScrollAnimation>
    {/*
    <Container>
        <div class="row">
            <div class="col-sm-6">ONE</div>
            <div class="col-sm-6">TWO</div>
        </div>
    </Container>*/}
    </section>
  )
}