import {Button, Card} from "react-bootstrap";
import {Container, Row, Col} from "react-bootstrap";
import places from "../assets/images/places.svg";
import ScrollAnimation from 'react-animate-on-scroll';


export const About = () =>  {
  return (
    <section className="about">
            <ScrollAnimation animateIn="fadeInUp">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} sm = {12} md={6} xl={6}>
                        <div className="animate__animated animate__fadeInLeft">
                            <img src={places} alt="places image"/>
                        </div>
                    </Col>
                    <Col xs={12} sm = {12} md={6} xl={6}>
                        <p>I grew up in Hong Kong and lived in the Bay Area for a couple of years before moving to Cambridge, MA for college.
                            I started coding by making choose-your-own adventure games with my friends, and I had the opportunity to teach classes on machine learning, wearable technologies, and 3D printing in high school, which fostered my passion for 
                        </p>
                        <h3>technology, design, and education.</h3>
                    </Col>
                </Row>
            </Container>
            </ScrollAnimation>
    </section>
  )
}