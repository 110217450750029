import {Button, Container, Row, Col, Card} from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import bears from "../assets/images/bears_nn.png";
import dining from "../assets/images/dining_app.png";
import heart from "../assets/images/heartdisease.png";
import scratch from "../assets/images/warriorsgame.png";
import art from "../assets/images/image_editing.jpg";
import warriors from "../assets/images/warriors.png";
import teamcity from "../assets/images/teamcity.png";
import edifii from "../assets/images/edifii.png";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export const Projects = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    return(
        <ScrollAnimation animateIn="fadeInUp">
    <section className="projects" id="projects">
        <h1>projects</h1>
        <Container>
            <Slider {...settings}>
                <div>
                    <Card className="projcard">
                    <Card.Img variant="top" height="250px" src={edifii} />
                    <Card.Body>
                        <Card.Title>Creating a Data Science based Personality Quiz for High School Counselors</Card.Title>
                        <p>Through my work with the edtech start up Edifii, I designed and implemented the front end of a personality quiz that was used to measure a high school student's Big 5 personality traits and Holland's Codes occupational themes to help counselors support students beyond high school.</p>
                        <p>The front end was developed in React, and the back end interacted with a Python script that allowed for the quiz results to be sent to the student's profiles. I also integrated generative AI to give insight on a student's personality based on their answer choices, such as suggesting a character from popular culture that they might identify with, or a movie or song that they might enjoy.</p>
                    </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card className="projcard">
                    <Card.Img variant="top" style={{ height: '250px', width: '100%' }} src={teamcity} />
                    <Card.Body>
                        <Card.Title>Creating High-impact Developer Tools</Card.Title>
                        <p>At my previous summer internship, I used the TeamCity API to create two new developer tools to improve developer productivity; these tools were written in Golang and were accessible directly from the command line.</p>
                        <p> The first tool allowed developers to push their jobs to the top of the build queue, which was helpful in the event that there was an urgent build that needed to bypass the long queue.</p>
                        <p>The second tool allowed for failed builds to be rerun by passing in the build IDs of one (or many) failed builds.</p>
                        <p>Together, these tools were useful when our company experienced a fire at one of our data centers and builds needed to be deployed quickly.</p>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                <Card className="projcard">
                    <Card.Img variant="top" height="250px" src={dining} />
                    <Card.Body>
                        <Card.Title>Help! Yelp for Dining Halls</Card.Title>
                        <p>Dining halls are a huge part of the lives of many MIT students, and what better way to figure out which of MIT's many dining halls to visit than asking for reviews from your friends?</p>
                        <p>This embedded system takes it a step further by letting you view reviews from students all across campus, and also allows you to submit your own feedback on the day's menu to inform others' decisions.</p>
                        <p>Users are able to view the ratings of all dining halls that are currently open, also listed by geographical distance from their current location- because maybe you're willing to eat a more subpar slice of pizza for dinner to save yourself the trek across campus.</p>
                        <p>◦ Developed on an ESP32 with code in Arduino (user-facing) and Python (server side)</p>
                    </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card className="projcard">
                    <Card.Img variant="top" height="250px" src={bears} />
                    <Card.Body>
                        <Card.Title>Developing Neural Network Curriculum</Card.Title>
                        <p>I worked with the Oracle Education Foundation as a teacher assistant for numerous technology classes throughout high school on topics including IoT, 3D printing, and wearable technologies; piloting the new neural network class allowed me to both employ my technical experience and call on my passion for teaching to effectively introduce students to new technologies.</p>
                        <p>I created a series of Google Colab notebooks to guide students through training a neural network to classify images of grizzly bears and black bears, employing the Fast.ai library.</p>
                    </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card className="projcard">
                    <Card.Img variant="top" height="250px" src={warriors} />
                    <Card.Body>
                        <Card.Title>Training a Character-Level GPT</Card.Title>
                        <p>Since the popularity of ChatGPT, I thought it might be fun to see if I could train my own neural network with the specific task of generating text that mimics that of the warrior cats books, which was one of my favourite book series growing up (with 120 books and counting!). I watched Andrej Karpathy’s video on nanoGPT and used the first installment of the warrior cats series, Into the Wild, as the input text with some interesting results.</p>
                    </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card className="projcard">
                    <Card.Img variant="top" height="250px" src={heart} />
                    <Card.Body>
                        <Card.Title>Predicting Heart Disease</Card.Title>
                        <p>Working with professors at the University of California, Santa Cruz, I trained a neural network to predict whether a patient was likely to have heart disease based on 13 different attributes</p>
                        <p>We used Keras and Tensorflow to work with extracted data instances, and I learned to mitigate codependency using dropout and regualizer techniques to address the challenge of overfitting.</p>
                    </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card className="projcard">
                    <Card.Img variant="top" height="250px" src={scratch} />
                    <Card.Body>
                        <Card.Title>Choose-your-own-adventure Game</Card.Title>
                        <p>I first became interested in coding when I came across games on MIT's introductory coding platform Scratch when I was in middle school. Now as a college student, I am a mentor for a club on campus called CodeIt where we teach local middle school girls how to code on Scratch, and I thought that creating my own game would be an amazing opportunity for me to re-familiarize myself with the platform and hit myself with a dose of childhood nostalgia.</p>
                        <p>I created all of my own graphics and animations for the game, and also came up with my own storyline with numerous branches depending on the player's decisions.</p>
                    </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card className="projcard">
                    <Card.Img variant="top" height="250px" src={art} />
                    <Card.Body>
                        <Card.Title>Image Modifications</Card.Title>
                        <p>When I first started posting my digital art online, one of my friends warned me to make sure to add a watermark to my work so that it would be more difficult for others to use without my permission.</p>
                        <p>I created a watermark and a tool that can be used to add the watermark on any image. I also worked on features such as inverting colors, converting images to black and white, and implementing seam carving to allow for content-aware image resizing.</p>
                    </Card.Body>
                    </Card>
                </div>
            </Slider>
            
        </Container>

        

    </section>
    </ScrollAnimation>
    )
}