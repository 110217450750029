import {Button, Container, Row, Col} from "react-bootstrap";
// import header_img from "../assets/images/header_img.svg"
import header_img from "../assets/images/header24.png"
import {useState, useEffect} from "react";
import 'animate.css';
import resume from "../assets/files/HuangAlexisResume24_5.pdf";

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const [index, setIndex] = useState(1);
    const toRotate = [ "Alexis Huang", "a master's student at MIT", "studying EECS & Finance", "happy to see you :)" ];
    const period = 200;

    useEffect(() => {
        let ticker = setInterval(() => {
        tick();
        }, delta);

        return () => { clearInterval(ticker) };
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
        setDelta(prevDelta => prevDelta / 2);
        }

        if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setIndex(prevIndex => prevIndex - 1);
        setDelta(period);
        } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setIndex(1);
        setDelta(250);
        } else {
        setIndex(prevIndex => prevIndex + 1);
        }
    }
    return(
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} sm = {12} md={6} xl={7}>
                        <span className="tagline">Welcome!</span>
    <h1 className="typing">{`Hi, I'm `}<span className="wrap">{text}</span></h1>
                        <h5 className="first-intro">I'm Alexis Huang, currently pursuing my master's in engineering at the MIT Media Lab's Lifelong Kindergarden Group.</h5>
                        <h5 className="first-intro">I graduated from MIT with a bachelor's degree in Electrical Engineering + Computer Science and a minor in Finance this past spring!</h5>
                        <h3>technology. design. education.</h3>
                        <p className="first-intro">I'm currently working on my master's thesis on integrating AI into new features on Scratch, a block-based programming website for kids.</p>
                        <p className="first-intro">Outside of the classroom, I love drawing, cooking experiments, teaching, and dance.</p>
                        <p className="first-intro">Thank you for stopping by!</p>
                        <a href={resume} download>
                            <Button className="resume-button first-intro" variant="light" >download my resume</Button>
                        </a>
                    </Col>
                    <Col xs={12} sm = {12} md={6} xl={5}>
                        <div className="animate__animated animate__pulse animate__infinite">
                            <img src={header_img} alt="Header" style={{ maxWidth: '100%', maxHeight: '500px' }}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}